<template>
	<router-view></router-view>

	<!-- <router-view v-slot="{ Component }">
		<Transition name="route" mode="out-in">
			<component :is="Component"></component>
		</Transition>
	</router-view> -->
</template>

<script>
export default {
	name: 'App',
	components: {
	}
}
</script>

<style>
.route-enter-from {
	opacity: 0;
	transform: translateX(100px);
}

.route-enter-active {
	transition: all .3s ease-out;
}

.route-leave-to {
	opacity: 0;
	transform: translateX(-100px);
}

.route-leave-active {
	transition: all .3s ease-in;
}
</style>