import { createI18n } from 'vue-i18n';

function loadLocaleMessages() {
	const locales = require.context('../config/lang', true, /[A-Za-z0-9-_,\s]+\.json$/i);
	const messages = {};
	locales.keys().forEach((key) => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
			const locale = matched[1];
			messages[locale] = locales(key);
		}
	});
	return messages;
}

const dateMalaysia = {
	long: {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		weekday: 'long',
		hour: 'numeric',
		minute: 'numeric',
		timeZone: 'Asia/Kuala_Lumpur',
	},
	short: {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		timeZone: 'Asia/Kuala_Lumpur',
	}
};

const dateUs = {
	long: {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		weekday: 'long',
		hour: 'numeric',
		minute: 'numeric',
		timeZone: 'Asia/Kuala_Lumpur',
	},
	short: {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		timeZone: 'Asia/Kuala_Lumpur',
	}
};

const datetimeFormats = {
	'my': dateMalaysia,
	'en': dateUs
}

export default createI18n({
	locale: process.env.VUE_APP_I18N_LOCALE || 'en',
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
	messages: loadLocaleMessages(),
	datetimeFormats,
});