import Swal from "sweetalert2";

export const handleErrorResponse = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status == 429) {
            Swal.fire({
                title: "Opss, too many requests!",
                text: "Please try again in 5 minutes.",
                imageUrl: require("@/assets/images/errors/429.svg"),
                imageWidth: 400,
                imageHeight: 300,
                imageAlt: "429-error",
                confirmButtonColor: "#12B76A",
                timer: 10000,
            }).then((result) => {
				if (result) {
                    localStorage.clear();
                    sessionStorage.clear();
					window.location.href = "/";
				}
			});
        } else if (error.response.status == 401) {
            if (window.location.pathname != "/login") {
                Swal.fire({
                    title: "Opss, your session have expired!",
                    text: "You will be redirected to login page.",
                    imageUrl: require("@/assets/images/errors/401.svg"),
                    imageWidth: 400,
                    imageHeight: 300,
                    imageAlt: "401-error",
                    confirmButtonColor: "#12B76A",
                    timer: 10000,
                }).then((result) => {
                    if (result) {
                        window.location.href = "/";
                    }
                });
            }
        }

        let textmessage = '';
        if(error.response.data.meta != undefined) {
            if (error.response.data.meta.message || error.response.data.meta.message != undefined) {
                textmessage = error.response.data.meta.message;
            } else {
                textmessage = "We're sorry, but an error has occurred. Please try again or contact HQ if the issue persists.";
            }
        } else {
            textmessage = "We're sorry, but an error has occurred. Please try again or contact HQ if the issue persists.";
        }

        return {
            statusCode: error.response.status,
            message: textmessage,
        };
    } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);

        if (error.request.status == 429) {
            Swal.fire({
                title: "Opss, too many requests!",
                text: "Please try again in 5 minutes.",
                imageUrl: require("@/assets/images/errors/429.svg"),
                imageWidth: 400,
                imageHeight: 300,
                imageAlt: "429-error",
                confirmButtonColor: "#12B76A",
                timer: 10000,
            }).then((result) => {
				if (result) {
                    localStorage.clear();
                    sessionStorage.clear();
					window.location.href = "/";
				}
			});
        } else if (error.request.status == 401) {
            if (window.location.pathname != "/login") {
                Swal.fire({
                    title: "Opss, your session have expired!",
                    text: "You will be redirected to login page.",
                    imageUrl: require("@/assets/images/errors/401.svg"),
                    imageWidth: 400,
                    imageHeight: 300,
                    imageAlt: "401-error",
                    confirmButtonColor: "#12B76A",
                    timer: 10000,
                }).then((result) => {
                    if (result) {
                        window.location.href = "/";
                    }
                });
            }
        }

        return {
            statusCode: 0,
            message: 'No response from server',
        };
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);

        if (error.message == "Unauthenticated") {
            Swal.fire({
                title: "Opss, your session have expired!",
                text: "You will be redirected to login page.",
                imageUrl: require("@/assets/images/errors/401.svg"),
                imageWidth: 400,
                imageHeight: 300,
                imageAlt: "401-error",
                confirmButtonColor: "#12B76A",
                timer: 10000,
            }).then((result) => {
				if (result) {
					window.location.href = "/";
				}
			});
        }

        return {
            statusCode: -1,
            message: error.message,
        };
    }
};