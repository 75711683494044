import { createApp, ref } from 'vue';
import { createHead } from "@unhead/vue";
import router from "./router";
import App from './App.vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
import store from "./state/store";
import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';
import VueFeather from 'vue-feather';
import Particles from "particles.vue3";
import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import '@/registerServiceWorker'
import i18n from '@/helpers/i18n';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import * as Sentry from "@sentry/vue";
import VueBlocksTree from 'vue3-blocks-tree';
import 'vue3-blocks-tree/dist/vue3-blocks-tree.css';
// import { datadogRum } from '@datadog/browser-rum';

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

const head = createHead()

const app = createApp(App)

// Define a method to update the cart count
const cartCount = ref(0);
app.config.globalProperties.$cartCount = cartCount;
app.config.globalProperties.$updateCartCount = (newCount) => {
  cartCount.value = newCount;
};

// Define global currency formatter
app.config.globalProperties.$formatCurrency = (amount, currency, decimals = 2, showSymbol = true) => {
    let locale, formattedCurrency;

    if (currency === 'Rp') { 
        formattedCurrency = 'IDR';
        locale = 'id-ID';
        decimals = 0;
    } else {
        formattedCurrency = 'MYR';
        locale = 'ms-MY';
    }

    const options = {
        currency: formattedCurrency,
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
        style: showSymbol ? 'currency' : 'decimal'
    };

    return new Intl.NumberFormat(locale, options).format(amount);
};

// localStorage.setItem('userInfo', JSON.stringify(store.state.auth));

const chatBotControl = JSON.parse(localStorage.getItem('chatBot'));

Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// datadogRum.init({
//     applicationId: 'c5ba9647-1cb5-4b52-bc7d-8b9afe02cbaf',
//     clientToken: 'pube831ff9be2ca43e174ee0fb71e4ab063',
//     site: 'us5.datadoghq.com',
//     service: 'v3-production',
//     // env: 'staging',
//     // Specify a version number to identify the deployed version of your application in Datadog
//     // version: '1.0.0',
//     sessionSampleRate: 100,
//     sessionReplaySampleRate: 100,
//     trackUserInteractions: true,
//     trackResources: true,
//     trackLongTasks: true,
//     defaultPrivacyLevel: 'mask-user-input',
// });

if (window.location.pathname === "/login") {
    localStorage.removeItem('chat_session')
}
// Now you can use the 'control' value as needed in your main.js file
if (chatBotControl == true && (window.location.pathname !== '/login' && window.location.pathname !== '/no-access')){
    !(function () {
        let e = document.createElement("script"),
         t = document.head || document.getElementsByTagName("head")[0];
        (e.src = "https://cdn.jsdelivr.net/npm/rasa-webchat@1.0.1/lib/index.js"),
         (e.async = !0),
         (e.onload = () => {
          window.WebChat.default(
           {
            customData: { language: "en" },
            initPayload: "SpecialInitPayLoadDoNotTouch",
            socketUrl: "https://admin-mesolitica-keycloak-com-5-rasa.ejen2u.mesolitica.com",
            title: "Cik Nad",
            showFullScreenButton: true,
            showMessageDate: true,
             mainColor: "#18ad81",
            conversationBackgroundColor: "#E3EFE6",
            userTextColor: "white",
            userBackgroundColor: "#298D64",
            assistTextColor: "dimgrey",
            assistBackgoundColor: "honeydew",
            profileAvatar: "https://happytohelpmeso.sirv.com/Images/cik%20nad.png",
           },
           null
          );
         }),
         t.insertBefore(e, t.firstChild);
       })();   
}

app.use(head)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(Particles)
    .use(vClickOutside)
    .use(i18n)
    .use(VueDOMPurifyHTML)
    .component('blocks-tree',VueBlocksTree)
    .mount('#app');
