import { createWebHistory, createRouter } from "vue-router";
// import axios from '../helpers/apiservice/axios';
import routes from "./routes";
import appConfig from "@/../app.config";
import store from "@/state/store";
import { AES, enc } from "crypto-js";
import axiosHttpd from "../helpers/apiservice/axios-http";
import * as Sentry from '@sentry/vue';

const router = createRouter({
	history: createWebHistory(),
	routes,
	// Use the HTML5 history API (i.e. normal-looking routes)
	// instead of routes with hashes (e.g. example.com/#/about).
	// This may require some server configuration in production:
	// https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
	mode: "history",
	// Simulate native-like scroll behavior when navigating to a new
	// route and using back/forward buttons.
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { top: 0, left: 0 };
		}
	},
});

// API based Authentication
// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
	Sentry.configureScope((scope) => scope.setTransactionName(routeTo?.meta?.label));
	// next();
	const authRequired = localStorage.getItem("authToken");
	
	if (routeTo.matched.some(record => record.meta.authRequired)) {
		if (authRequired) {
			try {
				const decrypted = AES.decrypt(
					localStorage.getItem("authToken"),
					"EJENGO2023"
				);
				const decryptedString = decrypted.toString(enc.Utf8);
		
				const currentUser = store.state.auth.currentUser;
		
				if (currentUser) {
					// If user details are already in Vuex store, no need to make a backend request
					next();
				} else {
					axiosHttpd.axiosInstance.defaults.headers.common["authorization"] =
						"Bearer " + decryptedString; // for all requests
					const response = await axiosHttpd.axiosInstance.get("/user/detail");
					if (!response.data.data.subscription) {
						localStorage.clear();
						sessionStorage.clear();
                        next({ name: "subscription-expired" });
                    } else {
                        store.commit("auth/SET_CURRENT_USER", response.data.data);
                        next();
                    }
				}
			} catch (error) {
				localStorage.removeItem("authToken");
				next({ name: "login", query: { redirectFrom: routeTo.fullPath } });
			}
		} else {
			next({ name: "login", query: { redirectFrom: routeTo.fullPath } });
		}
	} else {
		next();
	}
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
	// Create a `beforeResolve` hook, which fires whenever
	// `beforeRouteEnter` and `beforeRouteUpdate` would. This
	// allows us to ensure data is fetched even when params change,
	// but the resolved route does not. We put it in `meta` to
	// indicate that it's a hook we created, rather than part of
	// Vue Router (yet?).
	try {
		// For each matched route...
		for (const route of routeTo.matched) {
			await new Promise((resolve, reject) => {
				// If a `beforeResolve` hook is defined, call it with
				// the same arguments as the `beforeEnter` hook.
				if (route.meta && route.meta.beforeResolve) {
					route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
						// If the user chose to redirect...
						if (args.length) {
							// If redirecting to the same route we're coming from...
							// Complete the redirect.
							next(...args);
							reject(new Error("Redirected"));
						} else {
							resolve();
						}
					});
				} else {
					// Otherwise, continue resolving the route.
					resolve();
				}
			});
		}
		// If a `beforeResolve` hook chose to redirect, just return.
	} catch (error) {
		return;
	}
	document.title = routeTo.meta.title + " | " + appConfig.title;
	// If we reach this point, continue resolving the route.
	next();
});

export default router;
